import  { createClient } from "@sanity/client"
import imageUrlBuilder from "@sanity/image-url"

export   const client = createClient({
  projectId: "0xzc3zk1", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // this is from those question during 'sanity init'
  useCdn: true,
  apiVersion:"2022-10-21"
});


const builder = imageUrlBuilder(client);
export const urlFor =(source)=>builder.image(source)