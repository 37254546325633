import React, { useState, useEffect } from "react";
import { client } from "../../Client";
import Strip from "../../assets/pic2.png";
import { Container, Row, Col, Button } from "reactstrap";
import "./Data.css";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Search from "../Search/Search";
import { motion } from "framer-motion";
import { fadeIn } from "../motion/Motion";
// import { urlFor } from '../../Client'
import imageUrlBuilder from "@sanity/image-url";

const Data = () => {
  const builder = imageUrlBuilder(client);
  function urlFor(source) {
    return builder.image(source);
  }

  const [blogs, setBlogs] = useState([]);
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        publishedAt,
        mainImage{
          asset->{
          _id,
          url
        }
      },
      body,
      "name": author->name,
      "authorImage": author->image,
      "bio": author->bio,
      tags
    } | order(publishedAt desc) `
      )
      .then((data) => {
        setBlogs(data);
        setFilter(data);
      });
  }, []);
  const handleFilter = (item) => {
    if (item === "All") {
      setFilter(blogs);
    }  else {
      const filteredBlogs = blogs.filter((blog) =>
        blog.tags && blog.tags.includes(item)
      );
      setFilter(filteredBlogs);
    }
    // console.log(filter);
  };
  
  if (!blogs) return <span class="loader"></span>;
  return (
    <div className="container">
      <div
        className="categories"
        style={{
          display: "flex",
          gap: "1rem",
          color: "#ffF",
          cursor: "pointer",
        }}
      >
        {[
          "Web Development",
          "Graphic Designing",
          "Video Editing",
          "Design",
          "All",
        ].map((item, idx) => (
          <p className="tags" onClick={() => handleFilter(item)} key={idx}>
            {item}
          </p>
          
        ))}
      </div>
      <div className="data__main">
        <h1 style={{ fontWeight: "bold" }}>Services For You</h1>
        <Search />
        </div>
       
        {/* <img src={Strip} alt="" /> */}
     
      <div className="blog__post">
        <Container>
          <Row>
            {filter && filter.length > 0 ? (
              filter.map((post, idx) => (
                <Col lg={4} sm={6} key={idx}>
                  <motion.div
                     initial='hidden'
                     whileInView='show'
                      variants={fadeIn("up" ,"spring", idx * 0.2 ,0.1)}
                      viewport={{once:"false" , amount:0}}
                     
                  
                     className="blog__data" >
                         <img src={urlFor(post.mainImage)} alt="" loading='lazy' />                       
                        <Link className='link' style={{textDecoration:"none" ,color:"#000" , fontWeight:700}} to={"/OnePost/" + post.slug.current} key={post.title} > <h4>{`${post.title.substring(0,55)}...`}</h4></Link>
                        <p style={{color:"red" ,fontWeight:'bold'}}> {post.name} {format(new Date(post.publishedAt), "dd MMMM yyyy")}</p>
                        {/* <p>{`${post.body[0].children[0].text.substring(0,100)}.....`}</p> */}
                       <Link  style={{textDecoration:"none" ,color:"#000"}} to={"/OnePost/" + post.slug.current} key={post.slug.current} ><span>Get Now</span></Link>
                    
                     </motion.div>
                </Col>
              ))
            ) : (
              <p>No results found</p>
            )}
          </Row>

          <Link style={{ textDecoration: "none" }} to="/AllBlogs">
            {" "}
            <button>All Services</button>
          </Link>
        </Container>
      </div>
    </div>
  );
};

export default Data;
